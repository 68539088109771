import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as queryString from "querystring";
import { logIn } from "../features/auth/authSlice";
import { useAppDispatch } from "../app/hooks";

const Auth = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const accessToken =
    (location &&
      location.hash &&
      queryString.parse(location.hash)["id_token"]) ||
    queryString.parse(location.hash)["#id_token"];

  useEffect(() => {
    if (accessToken) {
      // authService.authenticate(accessToken as string);
      dispatch(logIn(accessToken as string));
      // dispatch(getUser());
      navigate("/");
    } else {
      navigate("/login");
    }
  }, [accessToken, dispatch, navigate]);

  return <div>id_token={}</div>;
};

export default Auth;

import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import "./App.css";
import { useRoutes } from "react-router-dom";
import { store } from "./app/store";
import theme from "./config/theme";
import { Provider } from "react-redux";
import routeConfig from "./config/routes";
import ApiFactory from "./app/api";
import { logOut } from "./features/auth/authSlice";
import { AxiosStatic } from "axios";
import SnackBar from "./features/sidebar/SnackBar";

export const api: AxiosStatic = ApiFactory(
  process.env.REACT_APP_CORE_API || "",
  store,
  logOut
);

function App() {
  const routes = useRoutes(routeConfig);
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>{routes}</ThemeProvider>
      <SnackBar />
    </Provider>
  );
}

export default App;

import { createTheme } from "@mui/material/styles";
import { orange } from "@mui/material/colors";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    "custom-black":true;
  }
}

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
    optional: {
      main: string;
    };
    drawerWidth?: number;
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
    optional?: {
      main: string;
    };
    drawerWidth?: number;
  }
}
let theme = createTheme({});
theme.mixins.toolbar = {
  ...theme.mixins.toolbar,
  "@media (min-width:600px)": { minHeight: 50 },
};

console.log(theme.mixins.toolbar);

theme = createTheme(theme, {
  status: {
    danger: orange[500],
  },
  drawerWidth: 240,
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#EFEFEF",
    },
    action: {
      selected: "#EFEFEF",
      hover: "#7D7D7D",
      primary: "#EFEFEF",
    },
    optional: {
      main: "#7D7D7D",
    },
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "0.9rem",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: ["Open Sans"].join(","),
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        filled: {
          left: "-12px!important",
          top: "-9px!important",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            left: "-12px!important",
            top: "-9px!important",
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up("xs")]: {
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: "14px",
          textDecoration: "none",
        },
      },
    },
    // Name of the component
    MuiMenuItem: {
      styleOverrides: {
        root: {
          margin: "8px 8px 0px 8px",
          borderRadius: "5px",
          transition: "background 0.3s, color 0.5s",
          "&.Mui-selected": {
            color: "white",
            backgroundColor: "#7D7D7D",
            "&:hover": {
              backgroundColor: "#7D7D7D",
            },
          },
          "&:hover": {
            color: "white",
            backgroundColor: "#7D7D7D",
            boxShadow:
              "0 12px 20px -10px rgb( 125 125 125 / 28%), 0 4px 20px 0px rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(125 125 125 / 20%);",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          "&.Mui-focused": {
            color: "white",
          },
        },
      },
      option: {
        focused: {
          styleOverrides: {
            color: "white",
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        row: {
          "&:hover": {
            backgroundColor: "#7D7D7D",
          },
        },
      },
    },
    MuiButton: {
      variants: [      
        {
          props: { variant: "custom-black" },
          style: {
            color: "#FFFFFF" ,            
            backgroundColor: "#000000",
            boxShadow: `0 2px 2px 0 rgb(69 69 69 / 20%), 0 3px 1px -2px rgb(69 69 69 / 20%), 0 1px 5px 0 rgb(69 69 69 / 12%)`,
            "&:hover": {
              backgroundColor: "#555555",
              boxShadow:
                "0 14px 26px -12px rgb(72 72 72 / 20%), 0 4px 23px 0 rgb(72 72 72  / 20%), 0 8px 10px -5px rgb(72 72 72 / 20%)",
            },
              "&:disabled": {
                  color: "#FFFFFF",
              backgroundColor: "#555555",
              boxShadow:
                "0 14px 26px -12px rgb(72 72 72 / 20%), 0 4px 23px 0 rgb(72 72 72  / 20%), 0 8px 10px -5px rgb(72 72 72 / 20%)",
            
            },
          },
        },
      ],
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          "& .MuiTouchRipple-root": {
            color: "black",
          },
          fontSize: "1rem",
          textTransform: "none",
        },
        primary: {
          color: "red",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // padding: "6px!important",
          "& .MuiTouchRipple-root": {
            color: "black",
          },
          fontSize: "1rem",
        },
      },
    },
  },
});

export default theme;

import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import filterReducer from "../features/sidebar/filterSlice";
import dashBoardSlice from "../features/dashboard/dashBoardSlice";
import authReducer from "../features/auth/authSlice";
import snackbarSlice from "../features/snackbar/snackbarSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    counter: counterReducer,
    sideBarFilter: filterReducer,
    dashBoard: dashBoardSlice,
    snackBar: snackbarSlice,
  },
  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare({
      serializableCheck: {
        ignoredActions: ["pricat/get/fulfilled"],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

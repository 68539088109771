import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { authHeader } from "../auth/authSlice";
import { IFormValues } from "../sidebar/filterSlice";
import { api } from "../../App";
import { IPricatItem } from "../../pages/Dashboard/components/MockData";
import { ProductAction } from "../../pages/Dashboard/components/ActionButtons";
import JsFileDownloader from "js-file-downloader";

export interface IValidationError {
  key: string;
  text: string;
}

export interface IConflictItem {
  pricatField: string;
  assortmentField: string;
}

export interface IImageItem {
  url: string;
  title: string;
}

export interface IConflictResponse extends IConflictFields {
  pricatUpload: IPricatDataWithConflicts;
  assortment: IPricatData | null;
}
interface IData {
  id: number;
  value:string;
  label:string;
  isValid:boolean;
  validationMessage:string;
}
export interface IImageData {
    articleNumber: IData;
    extension:IData;
    filename:IData;
    id:string;
    imageType:IData;
    imageUrl:string;
    name:IData;
    sorting:IData;
    thumbnailUrl:string;
    webUrl:string;
    uploadedBy:string;
    uploadedData:string;
    validationError:string;
}

export interface IConflictFields {
  changeUpdates: IConflictItem[] | null;
  severeConflicts: IConflictItem[] | null;
}

export interface IPricatData {
  [key: string]: string;
}

type IPricatDataWithConflicts = (IConflictFields & IPricatData) | null;

interface IAllDataResponse {
  data: Array<IPricatData>;
  facets: IFormValues;
  total: number;
}

export interface IDashBoardSlice {
  selectedRow: any;
  selectedImages: any;
  facets: {};
  isPricatLoading: boolean;
  isConflictLoading: boolean;
  isSetForAllLoading: boolean;
  isImportExportLoading: boolean;
  isImageLoading: boolean;
  fileName: string;
  pricatData: Array<IPricatData>;
  conflictData: IConflictResponse;
  imageData: Array<IImageData>;
  historyData: IPricatData;
  showConflicts: boolean;
  validationErrors: {
    [key: string]: string;
  };
  total:number;
}

export enum IImportExport {
  SIZEGROUP = "sizegroup",
  PRODUCTS = "products",
  SIZETYPE = "sizetype",
  INTERNALSIZE = "internalsize"
}

const initialState: IDashBoardSlice = {
  selectedRow: null,
  selectedImages: null,
  facets: {},
  pricatData: [],
  isPricatLoading: false,
  isConflictLoading: false,
  isSetForAllLoading: false,
  isImportExportLoading: false,
  isImageLoading:false,
  fileName: '',
  validationErrors: {},
  showConflicts: true,
  imageData: [],
  conflictData: {
    pricatUpload: null,
    assortment: null,
    severeConflicts: null,
    changeUpdates: null,
  },
  historyData: {},
  total:0
};

export const getPricatData = createAsyncThunk<IAllDataResponse, IFormValues>(
  "pricat/alldata",
  async (filters, { rejectWithValue }) => {
    let body = {};
    if (Object.keys(filters).length) {
      body = { ...filters, LoadConflicts: true };
    }
    const headers = {
      ...authHeader(),
      "Content-Type": "application/json/plain",
    };
    try {
      const { data } = (await api.post("/pricat/alldata", body, {
        headers,
      })) as AxiosResponse<IAllDataResponse>;

      //patch all number fields to string for searching
      data?.data.map((productRow) => {
        productRow.id = productRow.id.toString();
        // productRow.fenixColorId = productRow.fenixColorId.toString();
        // productRow.fenixProductId = productRow.fenixProductId.toString();
        // productRow.fenixSkuId = productRow.fenixSkuId.toString();
        return productRow;
      });

      return data;
    } catch (error) {
      if (error) {
        return rejectWithValue(error);
      }
      return Promise.reject(error);
    }
  }
);

export const getConflictById = createAsyncThunk<IConflictResponse, number>(
  "conflict/get",
  async (pricatRowId) => {
    const params = new URLSearchParams();
    params.append("id", pricatRowId.toString());
    params.toString();
    const headers = {
      ...authHeader(),
    };
    const { data } = <AxiosResponse<IConflictResponse>> await api.get(
      "/pricat/conflict",
      {
        params,
        headers,
      }
    );
    return data;
  }
);

export const getHistoryById = createAsyncThunk<Array<IPricatData>, number>(
  "history/get",
  async (pricatRowId) => {
    const params = new URLSearchParams();
    params.toString();
    const headers = {
      ...authHeader(),
    };
    const { data } = <AxiosResponse<Array<IPricatData>>>await api.get(
      `/pricat/history/${pricatRowId.toString()}`,
      {
        params,
        headers,
      }
    );
    return data;
  }
);

export const batchUpdate = createAsyncThunk<
  Promise<void>,
  { idsToUpdate: Array<number>; action: ProductAction }
>("batch/update", async ({ idsToUpdate, action }) => {
  const headers = {
    ...authHeader(),
  };
  await api.put(
    "/pricat/statusbatchupdate",
    {
      Status: action,
      PricatUploadIds: idsToUpdate,
    },
    { headers }
  );
});

export const masterdataReady = createAsyncThunk<Promise<void>, Array<number>>(
  "batch/masterdataReady",
  async (idsToUpdate) => {
    const headers = {
      ...authHeader(),
    };
    await api.put("/pricat/masterdataReady", idsToUpdate, { headers });
  }
);

export const pimReady = createAsyncThunk<Promise<void>, Array<number>>(
  "batch/pimReady",
  async (idsToUpdate) => {
    const headers = {
      ...authHeader(),
    };
    await api.put("/pricat/pimReady", idsToUpdate, { headers });
  }
);

export const rejected = createAsyncThunk<Promise<void>, Array<number>>(
  "batch/rejected",
  async (idsToUpdate) => {
    const headers = {
      ...authHeader(),
    };
    await api.put("/pricat/rejected", idsToUpdate, { headers });
  }
);

export const done = createAsyncThunk<Promise<void>, Array<number>>(
  "batch/done",
  async (idsToUpdate) => {
    const headers = {
      ...authHeader(),
    };
    await api.put("/pricat/done", idsToUpdate, { headers });
  }
);

export const deletePricatRows = createAsyncThunk<Promise<void>, Array<number>>(
  "batch/delete",
  async (idsToDelete) => {
    const headers = {
      ...authHeader(),
    };
    await api.delete("/pricat/pricats", {
      headers,
      data: idsToDelete,
    });
  }
);

export const resolveConflict = createAsyncThunk<
  Promise<any>,
  { pricat: IPricatItem; action?: ProductAction }
>(
  "conflict/resolve/update",
  async ({ pricat, action }, { rejectWithValue }) => {
    const headers = {
      ...authHeader(),
    };
    try {
      return await api.put(
        "/pricat/conflict/resolve ",
        {
          status: action,
          pricat: pricat,
        },
        { headers }
      );
    } catch (error) {
      if (error?.response) {
        const validationErrorsMap = error?.response?.data.reduce(
          (acc: { [key: string]: string }, next: { [key: string]: string }) => {
            acc[next.key] = next.value;
            return acc;
          },
          {}
        );
        return rejectWithValue(validationErrorsMap);
      }
      return Promise.reject(error);
    }
  }
);

export const savePricatRow = createAsyncThunk<Promise<any>, IPricatItem>(
  "pricat/saveRow",
  async (pricatItem, { rejectWithValue }) => {
    const headers = {
      ...authHeader(),
    };
    try {
      return await api.put("/pricat/save ", pricatItem, { headers });
    } catch (error) {
      if (error?.response) {
        const validationErrorsMap = error?.response?.data.reduce(
          (acc: { [key: string]: string }, next: { [key: string]: string }) => {
            acc[next.key] = next.value;
            return acc;
          },
          {}
        );
        return rejectWithValue(validationErrorsMap);
      }
      return Promise.reject(error);
    }
  }
);

interface ISetForAll {
  itemIds: Array<string>;
  fields: {
    [key: string]: string;
  };
}

export const batchSave = createAsyncThunk<Promise<any>, ISetForAll>(
  "batch/save",
  async (setForAll, { rejectWithValue }) => {
    const headers = {
      ...authHeader(),
    };
    try {
      return await api.put("/pricat/setforall", setForAll, { headers });
    } catch (error) {
      if (error.response) {       
        rejectWithValue(error.response.data);
        return Promise.reject(error.response.data);     
      } else if (error.request) {        
        rejectWithValue(error.request);
        return Promise.reject(error.request);
      } else {
        rejectWithValue(error.message);
        return Promise.reject(error.message);        
      } 
    }
  }
);

export type IExportFilter = IFormValues & {
  ids?: Array<String>;
  exportType: string;
};

export const exportDataGrid = createAsyncThunk<Promise<any>, IExportFilter>(
  "pricat/export",
  async (filters, { rejectWithValue }) => {
    const headers = {
      ...authHeader(),
    };
    try {
      const { data } = await api.post(
        `/pricat/export/${filters.exportType}`,
        filters,
        {
          headers,
        }
      );
      return new JsFileDownloader({
        url: data,
        nativeFallbackOnError: true,
      });
      // return await new Promise((r, rej) => setTimeout(rej, 500));
    } catch (error) {
      rejectWithValue(error?.response);
      return Promise.reject(error);
    }
  }
);

export const importPricat = createAsyncThunk<
  Promise<any>,
  { file: File; importType: IImportExport }
>("pricat/import", async ({ file, importType }, { rejectWithValue }) => {
  const headers = {
    ...authHeader(),
    "Content-Type": "multipart/form-data",
  };
  const formData = new FormData();
  formData.append("file", file);
  try {
    await api.post(`/pricat/import/${importType}`, formData, {
      headers,
    });
  } catch (error) {
    rejectWithValue(error?.response?.data || error);
    return Promise.reject(error?.response?.data || error);
  }
});


export const getImages = createAsyncThunk<IImageData, number>(
  "images/get",
  async (pricatRowId) => {
    const params = new URLSearchParams();
    params.append("id", pricatRowId.toString());
    params.toString();
    const headers = {
      ...authHeader(),
    };
    const { data } = <AxiosResponse<IImageData>> await api.get(
      "/pricat/getimages",
      {
        params,
        headers,
      }
    );
    return data;
  }
);

const dashBoardSlice = createSlice({
  name: "dashBoard",
  initialState,
  reducers: {
    setShowConflicts(state) {
      state.showConflicts = !state.showConflicts;
    },
    setSelectedRow(state, action: PayloadAction<any>) {
      state.selectedRow = action.payload;
    },
    setSelectedImages(state, action: PayloadAction<any>) {
      state.selectedImages = action.payload;
    },
    resetValidationErrors(state) {
      state.validationErrors = {};
    },
    setConflictDataPricatUpload(
      state,
      action: PayloadAction<IConflictResponse>
    ) {
      state.conflictData = action.payload;
    },
    setFacets(state, action) {
      state.facets = action.payload;
    },
  },
  extraReducers: {
    [getImages.fulfilled.type]: (
      state, {payload}: PayloadAction<Array<IImageData>> 
      )=> {
          state.isImageLoading = false;
          state.imageData = payload as Array<IImageData>;
      },
      [getImages.pending.type]: (state) => {
        state.isImageLoading = true;
      },
      [getImages.rejected.type]: (state) => {
        state.isImageLoading = false;
        state = initialState;
      },
    [getPricatData.fulfilled.type]: (
      state,
      { payload }: PayloadAction<IAllDataResponse>
    ) => {
      state.isPricatLoading = false;
      state.pricatData = payload.data as Array<IPricatData>;
      delete payload.facets.total;
      state.facets = payload.facets;
      state.total = payload.total
      // state.selectedRow = null;
    },
    [getPricatData.pending.type]: (state) => {
      state.isPricatLoading = true;
    },
    [getPricatData.rejected.type]: (state) => {
      state.isPricatLoading = false;
      state = initialState;
    },
    [getConflictById.fulfilled.type]: (
      state,
      { payload }: PayloadAction<IConflictResponse>
    ) => {
      if (payload.pricatUpload && payload.severeConflicts) {
        payload.pricatUpload.severeConflicts = [...payload.severeConflicts];
      }
      if (payload.pricatUpload && payload.changeUpdates) {
        payload.pricatUpload.changeUpdates = [...payload.changeUpdates];
      }
      state.conflictData = payload;
      state.isConflictLoading = false;
    },
    [getConflictById.pending.type]: (state) => {
      state.isConflictLoading = true;
    },
    [getConflictById.rejected.type]: (state) => {
      state = initialState;
    },
    [batchUpdate.pending.type]: (state) => {
      state.isPricatLoading = true;
    },
    [batchUpdate.fulfilled.type]: (state) => {
      state.isPricatLoading = false;
    },
    [batchUpdate.rejected.type]: (state) => {
      state.isPricatLoading = false;
    },
    [resolveConflict.pending.type]: (state) => {
      state.validationErrors = {};
      state.isConflictLoading = true;
    },
    [resolveConflict.fulfilled.type]: (state) => {
      state.isConflictLoading = false;
    },
    [resolveConflict.rejected.type]: (state, payload) => {
      state.validationErrors = payload.payload;
      state.isConflictLoading = false;
    },
    [masterdataReady.pending.type]: (state) => {
      state.isConflictLoading = false;
    },
    [masterdataReady.fulfilled.type]: (state) => {
      state.isConflictLoading = false;
    },
    [masterdataReady.rejected.type]: (state) => {
      state.isConflictLoading = false;
    },
    [pimReady.pending.type]: (state) => {
      state.isConflictLoading = false;
    },
    [pimReady.fulfilled.type]: (state) => {
      state.isConflictLoading = false;
    },
    [pimReady.rejected.type]: (state) => {
      state.isConflictLoading = false;
    },
    [rejected.pending.type]: (state) => {
      state.isConflictLoading = false;
    },
    [rejected.fulfilled.type]: (state) => {
      state.isConflictLoading = false;
    },
    [rejected.rejected.type]: (state) => {
      state.isConflictLoading = false;
    },
    [done.pending.type]: (state) => {
      state.isConflictLoading = false;
    },
    [done.fulfilled.type]: (state) => {
      state.isConflictLoading = false;
    },
    [done.rejected.type]: (state) => {
      state.isConflictLoading = false;
    },
    [deletePricatRows.pending.type]: (state) => {
      state.isConflictLoading = false;
    },
    [deletePricatRows.fulfilled.type]: (state) => {
      state.isConflictLoading = false;
    },
    [deletePricatRows.rejected.type]: (state) => {
      state.isConflictLoading = false;
    },
    [savePricatRow.pending.type]: (state) => {
      state.isPricatLoading = true;
      state.isConflictLoading = true;
    },
    [savePricatRow.fulfilled.type]: (state) => {
      state.isPricatLoading = false;
      state.isConflictLoading = false;
    },
    [savePricatRow.rejected.type]: (state) => {
      state.isPricatLoading = false;
      state.isConflictLoading = false;
    },
    [batchSave.pending.type]: (state) => {
      state.isSetForAllLoading = true;
    },
    [batchSave.fulfilled.type]: (state) => {
      state.isSetForAllLoading = false;
    },
    [batchSave.rejected.type]: (state) => {
      state.isSetForAllLoading = false;
    },
    [exportDataGrid.pending.type]: (state) => {
      state.isImportExportLoading = true;
    },
    [exportDataGrid.fulfilled.type]: (state) => {
      state.isImportExportLoading = false;
    },
    [exportDataGrid.rejected.type]: (state) => {
      state.isImportExportLoading = false;
    },
    [importPricat.pending.type]: (state) => {
      state.isImportExportLoading = true;
    },
    [importPricat.fulfilled.type]: (state) => {
      state.isImportExportLoading = false;
    },
    [importPricat.rejected.type]: (state) => {
      state.isImportExportLoading = false;
    },
    [getHistoryById.pending.type]: (state) => {
      state.isConflictLoading = true;
    },
    [getHistoryById.fulfilled.type]: (state, { payload }) => {
      state.historyData = payload;
    },
    [getHistoryById.rejected.type]: (state) => {
      state.isConflictLoading = false;
    },
    // builder.addCase(getPricatData.fulfilled, (state, { payload }) => {
    //   state.pricatData = payload.data;
    // });
    // },
  },
});

export default dashBoardSlice.reducer;
export const {
  setSelectedRow,
  setSelectedImages,
  setConflictDataPricatUpload,
  resetValidationErrors,
  setShowConflicts,
} = dashBoardSlice.actions;

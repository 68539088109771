import { AlertColor } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncThunk } from "@reduxjs/toolkit";
import { UNAUTHORIZED } from "../../app/api";

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>;

export interface ISnackbarSlice {
  severity?: AlertColor;
  message?: string | { [key: string]: string };
  open: boolean;
  duration?: number;
}

const initialState = {
  severity: "info",
  message: {},
  open: false,
  duration: 103000,
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    setSnackBar(state, action: PayloadAction<ISnackbarSlice>) {
      state.severity = action.payload?.severity || "";
      state.message = (action.payload?.message || "") as string;
      state.open = action.payload.open;
    },
    resetSnackBar(state) {
      state.open = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    // handle all rejected requests
    builder.addMatcher(
      (action): action is RejectedAction => action.type.endsWith("/rejected"),
      (state, error: any) => {
        if (error?.payload?.response?.status === UNAUTHORIZED) {
          state.message = error?.payload?.response?.statusText;
        } else if (error?.payload && Object.keys(error?.payload).length !== 0) {
          state.message = error?.payload;
        } else {
          state.message = `${error?.error?.message} ${
            error?.payload?.message || ""
          }`;
        }
        state.severity = "error";
        state.open = true;
      }
    );
  },
});

export default snackbarSlice.reducer;
export const { setSnackBar, resetSnackBar } = snackbarSlice.actions;

import React from "react";
import ContentLoader from "react-content-loader";

const TableLoader = (props: any) => {
  return (
    <ContentLoader speed={2} viewBox="0 0 1060 550" {...props}>
      {Array(10)
        .fill("")
        .map((e, i) => {
          const random = Math.random() * (1 - 0.7) + 0.7;
          return (
            <React.Fragment key={i}>
              <rect
                x="30"
                y={13 + i * 40}
                rx="12"
                ry="12"
                width="12"
                height="12"
              />
              <rect
                x="64"
                y={13 + i * 40}
                rx="6"
                ry="6"
                width={200 * random}
                height="12"
              />
              <rect
                x="443"
                y={13 + i * 40}
                rx="6"
                ry="6"
                width={123 * random}
                height="12"
              />
              <rect
                x="683"
                y={13 + i * 40}
                rx="6"
                ry="6"
                width={78 * random}
                height="12"
              />
              <rect
                x="785"
                y={13 + i * 40}
                rx="6"
                ry="6"
                width={117 * random}
                height="12"
              />
              <rect
                x="968"
                y={13 + i * 40}
                rx="6"
                ry="6"
                width={83 * random}
                height="12"
              />

              <rect
                x="0"
                y={38 + i * 40}
                rx="6"
                ry="6"
                width="1060"
                height=".3"
              />
            </React.Fragment>
          );
        })}
    </ContentLoader>
  );
};

TableLoader.metadata = {
  name: "Sridhar Easwaran",
  github: "sridhareaswaran",
  description: "Loader for Tables",
  filename: "TableLoader",
};

export default TableLoader;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IFacetItem {
  [key: string]: string | number;
}

export interface IFormValues {
  limit?: number,
  skip?: number,
  brands?: string[];
  countries?: string[];
  importedByUser?: string[];
  statuses?: string[];
  uploadedDate?: string[];
  suppliers?: string[];
  categories?: string[];
  total?: number;
}

export interface IFacetValues {
  brands?: string[];
  countries?: string[];
  importedByUser?: string[];
  statuses?: string[];
  uploadedDate?: string[];
  suppliers?: string[];
  categories?: string[];
  total?: number;
}

interface IFilterSlice {
  facets: IFacetValues;
  formData: IFormValues;
}

const initialState: IFilterSlice = {
  facets: {},
  formData: {
    brands: [],
    countries: [],
    importedByUser: [],
    statuses: [],
    uploadedDate: [],
    suppliers: [],
    categories: [],
    skip: 0,
    limit: 50,
    total:0
  },
};

const filterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setFilter(state, action: PayloadAction<IFormValues>) {
      state.formData = {...state.formData, brands:action.payload.brands, countries:action.payload.countries, importedByUser:action.payload.importedByUser, 
                       statuses:action.payload.statuses, uploadedDate:action.payload.uploadedDate, suppliers:action.payload.suppliers, categories:action.payload.categories}   
    },
    setFacets(state, action: PayloadAction<IFacetValues>) {
      state.facets = action.payload;
    },
    setSkip(state, action: PayloadAction<number>){
      state.formData.skip = action.payload;
    },
    setLimit(state, action: PayloadAction<number>){
      state.formData.limit = action.payload;
    }
  },
});

export default filterSlice.reducer;
export const { setFilter, setFacets, setSkip, setLimit } = filterSlice.actions;

import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { resetSnackBar } from "../snackbar/snackbarSlice";
import { AlertColor } from "@mui/material";
import camelCaseToWords from "../../utils/camelCaseToWords";
import Box from "@mui/material/Box";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackBar() {
  const dispatch = useAppDispatch();
  const {
    open,
    severity = "info",
    message,
    duration,
  } = useAppSelector((state) => state.snackBar);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(resetSnackBar());
  };

  const getMessage = () => {
    if (
      typeof message === "object" &&
      message !== null &&
      message !== undefined &&
      Object.keys(message).length
    ) {
      return Object.keys(message).map((key: string) => {
        return (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <span>{camelCaseToWords(key)}:</span>
            <span>{(message as any)[key]}</span>
          </Box>
        );
      });
    }
    return <p>{message}</p>;
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={(severity || "info") as AlertColor}
          sx={{ width: "100%" }}
        >
          {getMessage()}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

import React, { Suspense } from "react";
import Auth from "../components/Auth";
import { RequireAuth } from "../components/RequireAuth";
import TableLoader from "../components/loaders/TableLoader";

// import { Dashboard, Login } from "../pages";
// import ProfilePage from "../pages/ProfilePage";
// import DashboardContainer from "../pages/Dashboard/components/DashboardContainer";

// lazy load componnents import
const ProfilePage = React.lazy(() => import("../pages/ProfilePage"));
const Dashboard = React.lazy(() => import("../pages/Dashboard/DashboardPage"));
const Login = React.lazy(() => import("../pages/LoginPage"));
const DashboardContainer = React.lazy(
  () => import("../pages/Dashboard/components/DashboardContainer")
);

const routeConfig = [
  {
    path: "/",
    element: (
      <Suspense fallback={<TableLoader />}>
        <Dashboard />
      </Suspense>
    ),
    children: [
      {
        path: "/",
        element: (
          <Suspense fallback={<TableLoader />}>
            <RequireAuth>
              <DashboardContainer />
            </RequireAuth>
          </Suspense>
        ),
      },
      {
        path: "profile",
        element: (
          <Suspense fallback={<p>LOADING</p>}>
            <RequireAuth>
              <ProfilePage />
            </RequireAuth>
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/login",
    element: (
      <Suspense fallback={<p>LOADING</p>}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: "/auth",
    element: (
      <Suspense fallback={<p>LOADING</p>}>
        <Auth />
      </Suspense>
    ),
  },
  {
    path: "*",
    element: (
      <Suspense fallback={<p>LOADING</p>}>
        <>NOPAGE</>
      </Suspense>
    ),
  },
];

export default routeConfig;

const forwardToLogin = () => {
  window.location.href = process.env.REACT_APP_LOGIN_URL || "";
  return null;
};

const authenticate = (token: string) => {
  localStorage.setItem("token", token);
};

const authService = {
  forwardToLogin,
  authenticate,
};
export default authService;

import axios, { AxiosStatic } from "axios";
import { AnyAction, Store } from "@reduxjs/toolkit";

export const UNAUTHORIZED = 401;

const ApiFactory = <T extends Store>(
  baseUrl: string,
  store: T,
  userSignOut: () => AnyAction
): AxiosStatic => {
  const { dispatch } = store; // direct access to redux store.
  baseUrl && (axios.defaults.baseURL = baseUrl);
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error?.response?.status;
      if (status === UNAUTHORIZED) {
        dispatch(userSignOut());
      }
      return Promise.reject(error);
    }
  );
  return axios;
};

export default ApiFactory;

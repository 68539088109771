import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import authService from "./authService";
import { api } from "../../App";

interface IAuthSlice {
  user: IUser | null;
  isLoggedIn: boolean;
}

interface IUser {
  name?: string;
}

const initialState: IAuthSlice = {
  user: null,
  isLoggedIn: Boolean(localStorage.getItem("token")),
};

export const authHeader = () => {
  const accessToken = localStorage.getItem("token");
  if (accessToken) {
    return { Authorization: "Bearer " + accessToken };
  } else {
    return false;
  }
};

export const getUser = createAsyncThunk(
  "user/get",
  async (_, { rejectWithValue }) => {
    const headers = {
      ...authHeader(),
      "Content-Type": "application/json/plain",
    };
    try {
      const response: any = await api.get<IUser>(
        "/user",
         //"https://royal-design-api-test.azurewebsites.net/api/user", //TODO change when deployed
        //"https://localhost:44386/api/user",
        { headers }
      );
      return (await response.data) as IUser;
    } catch (error) {
      if (error) {
        return rejectWithValue(error);
      }
      return Promise.reject(error);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      localStorage.removeItem("token");
    },
    logIn: (state, { payload }: PayloadAction<string>) => {
      authService.authenticate(payload);
      state.isLoggedIn = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      state.user = payload as IUser;
    });
  },
});

export default authSlice.reducer;
export const { logOut, logIn } = authSlice.actions;

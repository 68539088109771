const camelCaseToWords = (camelCaseString: string = "") => {
  const matched = camelCaseString.match(/^[a-z]+|[A-Z][a-z]*/g);
  if (!matched) {
    return camelCaseString;
  }
  return matched
    .map((x) => {
      return x[0].toUpperCase() + x.substr(1).toLowerCase();
    })
    .join(" ");
};

export default camelCaseToWords;
